import React from 'react';

export default function Monitor() {
  return (
    <svg
      className="align-top"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 300 200"
    >
      <g clip-path="url(#clip0_301_140)">
      <rect width="300" height="200" fill="white"/>
      <rect x="24.5" y="38.5" width="255" height="129.844" rx="7.5" fill="white"/>
      <rect x="24.5" y="38.5" width="255" height="129.844" rx="7.5" fill="black"/>
      <rect x="24.5" y="38.5" width="255" height="129.844" rx="7.5" stroke="black"/>
      <rect x="30.189" y="41.2034" width="243.622" height="124.438" fill="#FDFFE6" stroke="black"/>
      <path d="M66.6665 59.6272H138.305C141.679 59.6272 144.415 62.3628 144.415 65.7373V65.7373C144.415 69.1118 141.679 71.8473 138.305 71.8473H72.8993C69.457 71.8473 66.6665 74.6378 66.6665 78.0801V78.0801C66.6665 81.5224 69.457 84.3129 72.8993 84.3129H138.33C141.69 84.3129 144.415 87.0373 144.415 90.398V90.398C144.415 93.7588 141.69 96.4832 138.33 96.4832H72.8744C69.4459 96.4832 66.6665 99.2625 66.6665 102.691V102.691C66.6665 106.12 69.4458 108.899 72.8743 108.899H138.232C141.646 108.899 144.415 111.667 144.415 115.082V115.082C144.415 118.496 141.646 121.265 138.232 121.265H72.7492C69.3898 121.265 66.6665 123.988 66.6665 127.347V127.347C66.6665 130.707 69.3898 133.43 72.7491 133.43H138.332C141.691 133.43 144.415 136.153 144.415 139.513V139.513C144.415 142.872 141.691 145.595 138.332 145.595H66.6665" stroke="url(#paint0_linear_301_140)" stroke-width="12" stroke-linecap="round"/>
      <path d="M236.385 59.6272H164.747C161.372 59.6272 158.637 62.3628 158.637 65.7373V65.7373C158.637 69.1118 161.372 71.8473 164.747 71.8473H230.152C233.594 71.8473 236.385 74.6378 236.385 78.0801V78.0801C236.385 81.5224 233.594 84.3129 230.152 84.3129H164.722C161.361 84.3129 158.637 87.0373 158.637 90.398V90.398C158.637 93.7588 161.361 96.4832 164.722 96.4832H230.177C233.605 96.4832 236.385 99.2625 236.385 102.691V102.691C236.385 106.12 233.605 108.899 230.177 108.899H164.82C161.405 108.899 158.637 111.667 158.637 115.082V115.082C158.637 118.496 161.405 121.265 164.82 121.265H230.302C233.661 121.265 236.385 123.988 236.385 127.347V127.347C236.385 130.707 233.661 133.43 230.302 133.43H164.719C161.36 133.43 158.637 136.153 158.637 139.513V139.513C158.637 142.872 161.36 145.595 164.719 145.595H236.385" stroke="url(#paint1_linear_301_140)" stroke-width="12" stroke-linecap="round"/>
      <circle cx="163.378" cy="154.622" r="1.8963" fill="#2D8181"/>
      <path d="M145.15 173.278L147.405 167.718L152.603 168.207L154.067 172.578L181.317 182.55L183.809 185.719L150.711 175.533L119 185.874L120.315 182.631L145.15 173.278Z" stroke="black"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_301_140" x1="105.541" y1="59.6272" x2="105.541" y2="145.595" gradientUnits="userSpaceOnUse">
      <stop stopColor="#121F1F"/>
      <stop offset="1" stopColor="#2D8181"/>
      </linearGradient>
      <linearGradient id="paint1_linear_301_140" x1="197.511" y1="59.6272" x2="197.511" y2="145.595" gradientUnits="userSpaceOnUse">
      <stop stopColor="#121F1F"/>
      <stop offset="1" stopColor="#2D8181"/>
      </linearGradient>
      <clipPath id="clip0_301_140">
      <rect width="300" height="200" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
};